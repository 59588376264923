import { useQuery } from '@tanstack/react-query';

import { useIotServers } from '@braincube/header';
import { getDiskPartitionsList } from 'scenes/Home/services/api';

function usePartitionList() {
    const { selectedIotServer } = useIotServers();

    return useQuery({
        queryKey: ['DISK_PARTITIONS_LIST', { key: selectedIotServer?.productId }],
        queryFn: getDiskPartitionsList,
        enabled: !!selectedIotServer,
    });
}

export default usePartitionList;
