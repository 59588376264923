import { useQuery } from '@tanstack/react-query';

import { getNtlmCompatibility } from 'scenes/Home/services/api';

function useNtlmCompatibility() {
    return useQuery({
        queryKey: ['NTLM_COMPATIBILITY_KEY'],
        queryFn: getNtlmCompatibility,
        select: (datas) => datas.value,
    });
}

export default useNtlmCompatibility;
