import React, { useEffect, useMemo } from 'react';

import { CssBaseline, styled } from '@mui/material';
import { getLocaleKey, I18nProvider } from '@braincube/i18n';
import { HeaderIoTContextProvider, headerLabels, useIotServers } from '@braincube/header';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { localesMap, getBrowserLocale, getBaseUrl } from 'services';
import { BrowserRouter } from 'react-router-dom';
import { AppContextProvider } from 'contexts/appContext';
import { getBasePath } from 'services/helpers';
import { NotificationProvider, i18nLabels } from '@braincube/ui';
import Tracker from '@braincube/tracker';
import { AlertContextProvider } from 'services/context/AlertContext/AlertContext';
import { AppContent } from './components';

const StyledContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            networkMode: 'always',
        },
    },
});

function initTrackers(productName, user) {
    Tracker.configure({
        appName: import.meta.env.VITE_APP_NAME,
        apiName: productName,
        appVersion: import.meta.env.VITE_APP_VERSION,
        packageName: import.meta.env.VITE_APP_PACKAGE_NAME,
        matomoSiteId: import.meta.env.VITE_TRACKER_MATOMO_ID,
        user,
        isIotFrontApp: true,
    });
}

const appBasePath = getBasePath();
const baseUrl = getBaseUrl();

/**
 * This is the main component of our application, it manages the assembly and communication between our various components
 */
function App() {
    const { email, isCorporate, resourceName: productName } = useIotServers();

    useEffect(() => {
        if (productName) {
            initTrackers(productName, { email, ssoBranch: isCorporate ? 'CORPORATE' : 'CLIENT' });
        }
    }, [productName, email, isCorporate]);

    return (
        <BrowserRouter basename={appBasePath}>
            <AppContextProvider>
                <AlertContextProvider>
                    <AppContent />
                </AlertContextProvider>
            </AppContextProvider>
        </BrowserRouter>
    );
}

function ContextualizedApp() {
    const locale = getBrowserLocale();
    const localeKey = getLocaleKey(locale);

    const i18nProviderLabels = useMemo(() => {
        return {
            ...localesMap.getLabels(localeKey),
            ...headerLabels.getLabels(localeKey),
            ...i18nLabels.getLabels(localeKey),
        };
    }, [localeKey]);

    return (
        <I18nProvider labels={i18nProviderLabels} locale={localeKey}>
            <NotificationProvider>
                <QueryClientProvider client={queryClient}>
                    <StyledContainer>
                        <CssBaseline />
                        <HeaderIoTContextProvider baseUrl={baseUrl}>
                            <App />
                        </HeaderIoTContextProvider>
                    </StyledContainer>
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </NotificationProvider>
        </I18nProvider>
    );
}

export default ContextualizedApp;
