import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Collapse, Grid, styled, Toolbar } from '@mui/material';
import { useIotServers } from '@braincube/header';
import { useQuery } from '@tanstack/react-query';
import { useI18n } from '@braincube/i18n';
import { compare, hasAdminAccess } from 'services';
import { Appshortcut, AppHeader } from 'components';
import { useNotification } from '@braincube/ui';
import { getAppsList, getCoreAppsList } from './services/api';
import { NoApps, AppShortcutSkeletonList, SectionTitle, AlertCenter } from './components';

const StyledBackgroundWrapper = styled('main')(({ theme }) => ({
    paddingTop: 8,
    background: theme.palette.mode === 'light' ? 'linear-gradient(#E0E0E0, #FFF) fixed' : 'linear-gradient(#303030, #000) fixed',
    minHeight: 'calc( 100vh - 46px )',
    width: '100vw',
}));

const StyledContainer = styled('div')(({ theme }) => ({
    margin: theme.spacing(1),
}));

const StyledCollapse = styled(Collapse)(({ theme }) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
}));

const LOCAL_STORAGE_COLLAPSED_APPS_KEYS = {
    frontApps: 'iot-homepage-collapsed-front-apps',
    envApps: 'iot-homepage-collapsed-env-apps',
};

function getBaseApps(access = 'USER') {
    if (hasAdminAccess(access)) {
        return [
            {
                name: 'IoT Server Configuration',
                icon: 'AdministrationIot',
                href: `../../local-commands/iot-administration/`,
            },
        ];
    }
    return [];
}

function Home() {
    const { selectedIotServer, access } = useIotServers();
    const { enqueueError } = useNotification();
    const i18n = useI18n();

    const [collapsedFrontApps, setCollapsedFrontApps] = useState(
        localStorage.getItem(LOCAL_STORAGE_COLLAPSED_APPS_KEYS.frontApps) === 'true'
    );
    const [collapsedEnvApps, setCollapsedEnvApps] = useState(
        localStorage.getItem(LOCAL_STORAGE_COLLAPSED_APPS_KEYS.envApps) === 'true'
    );

    const {
        data: envApps,
        isLoading: isLoadingEnvApps,
        isError: envAppsOnError,
    } = useQuery({
        queryKey: ['ENV_APPS', { key: selectedIotServer?.productId }],
        queryFn: () => getCoreAppsList(selectedIotServer?.productId, access),
        enabled: !!selectedIotServer && !!access,
    });

    const {
        data: frontApps,
        isLoading: isLoadingFrontApps,
        isError: appsListOnError,
    } = useQuery({
        queryKey: ['APPS_LIST', { key: selectedIotServer?.productId }],
        queryFn: () => getAppsList(selectedIotServer?.productId),
        enabled: !!selectedIotServer && !!access,
    });

    const sortedApps = useMemo(() => {
        const envAppsList = envApps?.apps || getBaseApps(access);
        const frontAppsList = frontApps || [];

        return {
            envAppsList: envAppsList.sort(compare),
            frontAppsList: frontAppsList.sort(compare),
        };
    }, [access, envApps?.apps, frontApps]);

    const isLoadingApps = useMemo(() => {
        return isLoadingEnvApps || isLoadingFrontApps;
    }, [isLoadingEnvApps, isLoadingFrontApps]);

    const collapseFrontApps = useCallback(() => {
        setCollapsedFrontApps(!collapsedFrontApps);
        localStorage.setItem(LOCAL_STORAGE_COLLAPSED_APPS_KEYS.frontApps, !collapsedFrontApps);
    }, [collapsedFrontApps]);

    const collapseEnvApps = useCallback(() => {
        setCollapsedEnvApps(!collapsedEnvApps);
        localStorage.setItem(LOCAL_STORAGE_COLLAPSED_APPS_KEYS.envApps, !collapsedEnvApps);
    }, [collapsedEnvApps]);

    useEffect(() => {
        if (appsListOnError) {
            enqueueError(i18n.tc('notistack.errors.fetch.appsList'));
        }

        if (envAppsOnError) {
            enqueueError(i18n.tc('notistack.errors.fetch.appsEnv'));
        }
    }, [appsListOnError, enqueueError, envAppsOnError, i18n]);

    return (
        <>
            <AppHeader />
            <Toolbar />
            <AlertCenter />
            <StyledBackgroundWrapper>
                <StyledContainer>
                    {isLoadingApps ? (
                        <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                            <AppShortcutSkeletonList />
                        </Grid>
                    ) : (
                        <>
                            <SectionTitle
                                label={i18n.tc('sectionsTitle.frontApps')}
                                color="primary"
                                collapsed={collapsedFrontApps}
                                onCollapse={collapseFrontApps}
                                data-testid="sectionTitle-frontApps"
                            />
                            <StyledCollapse in={!collapsedFrontApps}>
                                {sortedApps.frontAppsList.length === 0 ? (
                                    <NoApps />
                                ) : (
                                    <Grid container direction="row" justifyContent="flex-start" spacing={2}>
                                        {sortedApps.frontAppsList.map((app) => (
                                            <Appshortcut key={app.name} {...app} subPath={envApps?.edgeSubPath} />
                                        ))}
                                    </Grid>
                                )}
                            </StyledCollapse>

                            {sortedApps.envAppsList.length > 0 && (
                                <>
                                    <SectionTitle
                                        label={i18n.tc('sectionsTitle.envApps')}
                                        color="secondary"
                                        collapsed={collapsedEnvApps}
                                        onCollapse={collapseEnvApps}
                                        data-testid="sectionTitle-envApps"
                                    />
                                    <Collapse in={!collapsedEnvApps}>
                                        <Grid container direction="row" justifyContent="flex-start" spacing={2} mt={1}>
                                            {sortedApps.envAppsList.map((app) => (
                                                <Appshortcut key={app.name} {...app} subPath={envApps?.edgeSubPath} />
                                            ))}
                                        </Grid>
                                    </Collapse>
                                </>
                            )}
                        </>
                    )}
                </StyledContainer>
            </StyledBackgroundWrapper>
        </>
    );
}

export default Home;
