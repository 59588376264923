import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

const defaultAlerts = {
    displayedAlerts: [],
    addAlert: () => {},
    hideAlert: () => {},
};

const AlertContext = createContext(defaultAlerts);

function AlertContextProvider({ children }) {
    const [alerts, setAlerts] = useState({});

    const addAlert = useCallback(
        (alertId, alertValue) => {
            if (alerts[alertId]) {
                return;
            }
            setAlerts({ ...alerts, [alertId]: alertValue });
        },
        [alerts]
    );

    const hideAlert = useCallback(
        (alertId) => {
            setAlerts({
                ...alerts,
                [alertId]: { ...alerts[alertId], visible: false },
            });
        },
        [alerts]
    );

    const displayedAlerts = useMemo(() => Object.entries(alerts).filter(([, alert]) => alert.visible, [alerts]), [alerts]);

    const providerValue = useMemo(
        () => ({
            displayedAlerts,
            addAlert,
            hideAlert,
        }),
        [addAlert, displayedAlerts, hideAlert]
    );

    return <AlertContext.Provider value={providerValue}>{children}</AlertContext.Provider>;
}

const useAlertContext = () => useContext(AlertContext);

export { AlertContextProvider, useAlertContext };
