import React, { useCallback, useEffect } from 'react';

import { Alert, Typography } from '@mui/material';
import { useI18n } from '@braincube/i18n';

import { useAlertContext } from 'services/context/AlertContext/AlertContext';
import useNtlmCompatibility from 'scenes/Home/services/hooks/useNtlmCompatibility';
import usePartitionList from 'scenes/Home/services/hooks/usePartitionList';
import { LIMIT_DISK_FULL } from 'scenes/Home/services/constants';

function AlertCenter() {
    const { displayedAlerts, addAlert, hideAlert } = useAlertContext();
    const i18n = useI18n();

    const { data: ntlmCompatibility } = useNtlmCompatibility();
    const { data: diskPartitionsList } = usePartitionList();

    useEffect(() => {
        if (ntlmCompatibility) {
            addAlert('ntlmCompatibility', {
                message: i18n.tc('banner.ntlmCompatibility'),
                severity: 'warning',
                visible: true,
                dataTestId: 'secuNtlmCompatibility',
            });
        }
    }, [ntlmCompatibility, i18n, addAlert]);

    useEffect(() => {
        if (diskPartitionsList && diskPartitionsList['/']) {
            const disk = diskPartitionsList['/'];
            if (disk.free / 1024 <= LIMIT_DISK_FULL) {
                addAlert('lowDiskSpace', {
                    message: i18n.tc('banner.lowDiskSpace', { limit: LIMIT_DISK_FULL }),
                    severity: 'warning',
                    visible: true,
                    dataTestId: 'bannerDiskAlmostFull',
                });
            }
        }
    }, [addAlert, diskPartitionsList, i18n]);

    const hideAlertItem = useCallback(
        (alertKey) => {
            hideAlert(alertKey);
        },
        [hideAlert]
    );

    return (
        <>
            {displayedAlerts.map(([key, alert]) => (
                <Alert severity={alert.severity} key={key} onClose={() => hideAlertItem(key)} data-testid={alert.dataTestId}>
                    <Typography>{alert.message}</Typography>
                </Alert>
            ))}
        </>
    );
}

export default AlertCenter;
